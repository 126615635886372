import { useFetch } from "../hooks/useFetch";
import Loader from "./Loader";
import Message from "./Message";

const SoatValor = ({ tittle, url }) => {
  const { data, error, loading } = useFetch(url);

  if (!data) return null;
  if (data.length < 1) return null;
  if (error) {
    return (
      <Message
        msg={`Error ${error.status}: ${error.statusText}`}
        bgColor="#dc3545"
      />
    );
  }
  return (
    <>
      {loading && <Loader />}
      {data && (
        <>
          <div className="soapp_valor">
            <h3>{tittle}</h3>
            <h1>{data[0].valor}</h1>
          </div>
        </>
      )}
    </>
  );
};

export default SoatValor;
