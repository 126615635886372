import { useFetch } from "../hooks/useFetch";
import Loader from "./Loader";
import Message from "./Message";

const SoatSelectList = ({ tittle, url, handleChange }) => {
  const { data, error, loading } = useFetch(url);

  if (!data) return null;
  if (data.length < 1) return null;
  if (error) {
    return (
      <>
        <Message msg={error} bgColor="#dc3545" />
      </>
    );
  }

  return (
    <>
      <div className="soapp_selector">
        <label htmlFor={tittle} className="soapp_label">
          {tittle}
        </label>
        <br />
        {loading && <Loader />}
        <select name={tittle} id={tittle} onChange={handleChange}>
          <option value="">selecciona...</option>
          {data &&
            data.map((el) => (
              <option key={el.id} value={el.id}>
                {el.Tipo || el.nombre || el.tiempo}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default SoatSelectList;
