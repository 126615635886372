import React, { useState } from "react";
import SoatSelectList from "./SoatSelectList";
import SoatValor from "./SoatValor";

const SoatSelect = () => {
  let url = "https://apisoat.masterweb.com.co/public/api/vehiculos";

  const [vehicles, setVehicles] = useState("");
  const [subtype, setSubtype] = useState("");
  const [age, setAge] = useState("");

  return (
    <div className="soapplistselector">
      <SoatSelectList
        tittle="CLASE DE VEHICULO"
        url={`${url}`}
        handleChange={(e) => {
          setVehicles(e.target.value);
          setSubtype("");
          setAge("");
        }}
      />
      {vehicles && (
        <SoatSelectList
          tittle="SUBTIPO DE VEHICULO"
          url={`${url}/${vehicles}/cilindraje`}
          handleChange={(e) => {
            setSubtype(e.target.value);
            setAge("");
          }}
        />
      )}
      {subtype && (
        <SoatSelectList
          tittle="EDAD DEL VEHICULO"
          url={`${url}/${vehicles}/cilindraje/${subtype}/tiempo`}
          handleChange={(e) => {
            setAge(e.target.value);
          }}
        />
      )}

      {age && subtype && (
        <SoatValor
          tittle="El valor del SOAT"
          url={`${url}/${vehicles}/cilindraje/${subtype}/tiempo/${age}/valor`}
        />
      )}

      {!age && subtype && (
        <SoatValor
          tittle="El valor del SOAT"
          url={`${url}/${vehicles}/cilindraje/${subtype}/tiempo/null/valor`}
        />
      )}

      {!age && !subtype && (
        <SoatValor
          tittle="El valor del SOAT"
          url={`${url}/${vehicles}/cilindraje/null/tiempo/null/valor`}
        />
      )}
    </div>
  );
};

export default SoatSelect;
