import React from "react";
import SoatSelect from "./components/SoatSelect";

function App() {
  return (
    <>
      <span>Desarrollado con &#x1F9E1; por <a href="https://masterweb.com.co">MasterWeb</a></span>
      <h1>Consulta el valor del SOAT</h1>
      <SoatSelect />
    </>
  );
}

export default App;
